import isPlainObject from 'is-plain-obj'
import { IMutation, IS_PROXY, VALUE } from 'proxy-state-tree'
import { StateMachine } from './statemachine'

export const IS_TEST = process.env.NODE_ENV === 'test'
export const IS_OPERATOR = Symbol('operator')
export const ORIGINAL_ACTIONS = Symbol('origina_actions')
export const EXECUTION = Symbol('execution')

export const MODE_DEFAULT = Symbol('MODE_DEFAULT')
export const MODE_TEST = Symbol('MODE_TEST')
export const MODE_SSR = Symbol('MODE_SSR')

export class MockedEventEmitter {
  emit() {}
  emitAsync() {}
  on() {}
  once() {}
  addListener() {}
}

export const json = <T>(obj: T): T => {
  return deepCopy(obj && obj[IS_PROXY] ? obj[VALUE] : obj)
}
  

export function isPromise(maybePromise: any) {
  return (
    maybePromise instanceof Promise ||
    (maybePromise &&
      typeof maybePromise.then === 'function' &&
      typeof maybePromise.catch === 'function')
  )
}

export function processState(state: {}) {
  return Object.keys(state).reduce((aggr, key) => {
    if (key === '__esModule') {
      return aggr
    }
    const originalDescriptor = Object.getOwnPropertyDescriptor(state, key)

    if (originalDescriptor && 'get' in originalDescriptor) {
      Object.defineProperty(aggr, key, originalDescriptor as any)

      return aggr
    }

    const value = state[key]

    if (isPlainObject(value)) {
      aggr[key] = processState(value)
    } else {
      Object.defineProperty(aggr, key, originalDescriptor as any)
    }

    return aggr
  }, isPlainObject(state) ? {} : state)
}

export function getFunctionName(func: Function) {
  return func.name || (func as any).displayName || ''
}

export function deepCopy(obj) {
  if (obj instanceof StateMachine) {
    return (obj as any).clone()
  } else if (isPlainObject(obj)) {
    return Object.keys(obj).reduce((aggr: any, key) => {
      if (key === '__esModule') {
        return aggr
      }

      const originalDescriptor = Object.getOwnPropertyDescriptor(obj, key)
      const isAGetter = originalDescriptor && 'get' in originalDescriptor
      const value = obj[key]

      if (isAGetter) {
        Object.defineProperty(aggr, key, originalDescriptor as any)
      } else {
        aggr[key] = deepCopy(value)
      }

      return aggr
    }, {})
  } else if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item))
  }

  return obj
}

const getChangeMutationsDelimiter = '.'
export function getChangeMutations(stateA: object, stateB: object, path: string[] = [], mutations: IMutation[] = []): IMutation[] {
  const stateAKeys = Object.keys(stateA)
  const stateBKeys = Object.keys(stateB)

  stateAKeys.forEach((key) => {
    if (!stateBKeys.includes(key)) {
      mutations.push({
        delimiter: getChangeMutationsDelimiter,
        args: [],
        path: path.concat(key).join('.'),
        hasChangedValue: false,
        method: 'unset',
      })
    }
  })

  stateBKeys.forEach((key) => {
    if (isPlainObject(stateA[key]) && isPlainObject(stateB[key])) {
      getChangeMutations(stateA[key], stateB[key], path.concat(key), mutations)
    } else if (stateA[key] !== stateB[key]) {
      mutations.push({
        delimiter: getChangeMutationsDelimiter,
        args: [stateB[key]],
        path: path.concat(key).join('.'),
        hasChangedValue: false,
        method: 'set'
      })
    }
  })

  return mutations
}


export function getActionPaths(actions = {}, currentPath: string[] = []) {
  return Object.keys(actions).reduce<string[]>((aggr, key) => {
    if (typeof actions[key] === 'function') {
      return aggr.concat(currentPath.concat(key).join('.'))
    }

    return aggr.concat(getActionPaths(actions[key], currentPath.concat(key)))
  }, [])
}

export function createActionsProxy(actions, cb) {
  return new Proxy(actions, {
    get(target, prop) {
      if (prop === ORIGINAL_ACTIONS) {
        return actions
      }

      if (typeof target[prop] === 'function') {
        return cb(target[prop])
      }

      if (!target[prop]) {
        return undefined
      }

      return createActionsProxy(target[prop], cb)
    },
  })
}
